import { createStore } from "vuex";
import getters from "./getters";
import state from "./state";
import locker from "./modules/locker";
import system from "./modules/system";
import startup from "./modules/startup";
import createPersistedState from "vuex-persistedstate";
export default createStore({
  modules: {
    locker,
    system,
    startup
  },
  getters,
  state,
  plugins: [createPersistedState()],
});
