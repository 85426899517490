import Formatter from 'currencyformatter.js';

// 带两位小数
const defaultOptions = {
  AUD: {symbol: 'A$'},
  CNY: {},
  GBP: {},
  SGD: {symbol: 'S$'},
  MYR: {},
  NZD: {symbol: 'NZ$'},
  USD: {},
  IDR: {abbr: true},
  INR: {symbol: '₹'},
}

// 没有小数
const noDecimal = {
  AUD: {pattern: '!#,##0'},
  CNY: {pattern: '!#,##0'},
  GBP: {pattern: '!#,##0'},
  SGD: {pattern: '!#,##0'},
  MYR: {pattern: '!#,##0'},
  NZD: {pattern: '!#,##0'},
  USD: {pattern: '!#,##0'},
  IDR: {pattern: '!#,##0'},
  INR: {pattern: '!#,##,##0'},
}

// options.noDecimal 不显示小数 默认为true
// options.abbr 是否缩写 默认为false
export default class Currency {
  static format(val, options) {
    var execOptions = {...defaultOptions[options.currency], ...options};
    // console.log('options', options);
    // 非简写并且指定不显示小数
    if ((options.noDecimal===undefined || options.noDecimal) && !execOptions.abbr) {
      execOptions = {...execOptions, ...noDecimal[options.currency]}
    }
    var unit = '';
    if (val) {
      if (execOptions.abbr) {
        if (execOptions.currency === 'IDR') {
          if (val >= 1000000000000) {
            val = val / 1000000000000; // T
            unit = 'T';
          } else if (val >= 1000000000) {
            val = val / 1000000000; // M
            unit = 'M';
          } else if (val >= 1000000) {
            val = val / 1000000; // Jt
            unit = 'Jt';
          }
        } else {
          if (val >= 1000000) {
            val = val / 1000000; // M
            unit = 'M';
          } else if (val >= 1000) {
            val = val / 1000; // K
            unit = 'K';
          }
        }
      }
    } else {
      val = '0';
    }
    return Formatter.format(val, execOptions)+unit;
  }

  static unformat(val, options) {
    var execOptions = {...defaultOptions[options.currency], ...options};
    // 非简写并且指定不显示小数
    if ((options.noDecimal===undefined || options.noDecimal) && !execOptions.abbr) {
      execOptions = {...execOptions, noDecimal:false}
    }
    // console.log('execOptions', execOptions);

    var unit = '';
    if (val) {
      if (execOptions.abbr) {
        if (execOptions.currency === 'IDR') {
          if (val >= 1000000000000) {
            val = val / 1000000000000; // T
            unit = 'T';
          } else if (val >= 1000000000) {
            val = val / 1000000000; // M
            unit = 'M';
          } else if (val >= 1000000) {
            val = val / 1000000; // Jt
            unit = 'Jt';
          }
        } else {
          if (val >= 1000000) {
            val = val / 1000000; // M
            unit = 'M';
          } else if (val >= 1000) {
            val = val / 1000; // K
            unit = 'K';
          }
        }
      }
    } else {
      val = '0';
    }
    return Formatter.format(val, execOptions)+unit;
  }

  static formatRange(from, to, split, options) {
    var from = Currency.format(from, options);
    var to = Currency.format(to, options);
    var symbol = defaultOptions[options.currency] || Formatter.symbols[options.currency];
    to = to.replace(symbol, '');
    // var from = Formatter.format(from, options).match(/-?[\,\.0-9]+/g);
    // var to = Formatter.format(to, options).match(/-?[\,\.0-9]+/g);
    return from+split+to;
  }

  static reeditContent(val, options) {
    var regex = /-?\$[\,\.0-9]+/g;
    var v1 = regex.exec(val);
    if (v1 && v1.length > 0) {
      var num = v1[0].replace('$', '').replaceAll(',', '');
      var v2 = Currency.format(num, options);
      val = val.replace(v1[0], v2);
      return Currency.reeditContent(val, options);
    } else {
      return val;
    }
  }

  static getCurrencyArray() {
    var keys = [];
    for (var key in defaultOptions) {
      if (key === 'IDR') {
        keys.push({label: 'Rp', value: key});
      } else {
        keys.push({label: key, value: key});
      }
    }
    return keys;
  }

  static getSymbol(currency) {
    // if (currnecy == '') {
    //   return ''
    // }
    if (currency === 'IDR') {
      return 'Rp';
    } else {
      return defaultOptions[currency].symbol || Formatter.symbols[currency];
    }
  }

}