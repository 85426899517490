import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import Storage from "@/utils/storage";
import en from "element-plus/es/locale/lang/en";
export default {
  setup() {
    const $store = useStore();
    const locale = computed(() => $store.getters.locale === "en" ? en : null);
    onMounted(() => {
      let localStyle = Storage.get("themeStyle") || "light";
      Storage.set("themeStyle", localStyle);
      $store.dispatch("system/changeTheme", localStyle);
      let localLanguage = $store.getters.locale || "en";
      document.getElementsByTagName("body")[0].className = localStyle + "-mode " + localLanguage;
    });
    return {
      locale
    };
  }
};