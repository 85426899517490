const getters = {
  languageType: (state) => state.system.languageType,
  locale: (state) => state.system.locale,
  theme: (state) => state.system.theme,
  startupInfo: (state) => state.startup.startupInfo,
  investments: (state) => state.startup.startupInfo.investment,
  refresh: (state) => state.startup.refresh,
  submitLock: (state) => {
    return function(path) {
      if (!path) {
        return !!state.locker.submit;
      }
      return path === state.locker.submit;
    }
  }
};
export default getters;