class Store {
  constructor() {
    this.store = window.localStorage;
    this.prefix = "GRAPE_AI_";
  }

  set(key, value, fn) {
    try {
      value = JSON.stringify(value);
    } catch (e) {
      // value = value
    }

    this.store.setItem(this.prefix + key, value);

    fn && fn();
  }

  get(key) {
    if (!key) {
      throw new Error("not found keys");
    }
    if (typeof key === "object") {
      throw new Error("key should not an object");
    }
    let value = this.store.getItem(this.prefix + key);
    if (value !== null) {
      try {
        value = JSON.parse(value);
      } catch (e) {
        // value = value
      }
    }
    return value;
  }

  remove(key) {
    this.store.removeItem(this.prefix + key);
  }
}

export default new Store();
