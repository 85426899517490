import moment from "moment";

const state = {
  startupInfo: {},
  refresh: null,
};

const mutations = {
  SET_STARTUP_INFO(state, value) {
    state.startupInfo = value;
  },
  SET_REFRESH(state, value) {
    state.refresh = value;
  },
};

const actions = {
  setStartupInfo({ commit }, params) {
    return new Promise((resolve) => {
      if (params) {
        commit("SET_STARTUP_INFO", params);
        commit("SET_REFRESH", moment.now())
      } else {
        commit("SET_STARTUP_INFO", {});
        commit("SET_REFRESH", moment.now())
      }
      resolve();
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
