import { ref, onMounted, onUnmounted } from 'vue';

export function usePageWidth() {
  const pageWidth = ref(window.innerWidth);

  const handleResize = () => {
    pageWidth.value = window.innerWidth;
  };

  onMounted(() => {
    window.addEventListener('resize', handleResize);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', handleResize);
  });

  return pageWidth;
}

export function isPC() {
  let userAgentInfo = navigator.userAgent;
  let Agents = [
    "Android",
    "iPhone",
    "SymbianOS",
    "Windows Phone",
    "iPod",
  ];
  console.log('Agents', userAgentInfo)
  let flag = true;
  for (let v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) >= 0) {
      flag = false;
      break;
    }
  }
  return flag;
}
