const state = {
  languageType: '', // 语言种类
  locale: "en",
  theme: "light", // auto, dark, light
};

const mutations = {
  SET_LANGUAGE_TYPE(state, languageType) {
    state.languageType = languageType;
  },
  SET_LOCAL: (state, locale) => {
    state.locale = locale;
  },
  SET_THEME: (state, theme) => {
    state.theme = theme;
  },
};

const actions = {
  setLanguagetype({ commit }, params) {
    return new Promise((resolve) => {
      commit("SET_LANGUAGE_TYPE", params);
      resolve();
    });
  },
  // 修改语言
  changeLocal({ commit }, params) {
    return new Promise((resolve) => {
      commit("SET_LOCAL", params);
      resolve();
    });
  },
  // 修改主题
  changeTheme({ commit }, params) {
    return new Promise((resolve) => {
      commit("SET_THEME", params);
      resolve();
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
