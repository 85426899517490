import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import VueI18n from "./language";
import store from "./store";
import Element from "element-plus";
import "element-plus/dist/index.css";
import "@/svgIcon/index.js";
import svgIcon from "@/svgIcon/index.vue";
import "@/assets/fonts/font.scss"; // 引入字体样式
import vClickout from "vue3-clickout";

import Vue3Autocounter from "vue3-autocounter";

const app = createApp(App)
  .use(store)
  .use(router)
  .use(VueI18n)
  .use(vClickout)
  .use(Element, {})
  .component("vue3-autocounter", Vue3Autocounter)
  .component("svgIcon", svgIcon);

// 定义全局函数
app.config.globalProperties.$tr = (key, param) => {
  var txt = VueI18n.global.t(key);
  if (!txt) {
    return null;
  }
  return txt.replace('%0%', param);
};
app.config.globalProperties.takeEmpty = (param) => {
  return param?param:'-';
};

import moment from 'moment';
app.config.globalProperties.covertDate = (param) => {
  if (param) {
    return moment(param).format('MMMM DD, YYYY');
  } else {
    return '-';
  }
};

import Currency from '@/utils/currency';
app.config.globalProperties.formatAmount = (param) => {
  if (param) {
    return Currency.format(param, {currency: 'USD'});
  } else {
    return '-';
  }
};

app.config.globalProperties.formatNumber = (param) => {
  if (param) {
    return param.toLocaleString();
  } else {
    return '-';
  }
};

router.isReady().then(() => {
  app.mount('#app');
});  