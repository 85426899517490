const state = {
  submit: undefined,
};

const mutations = {
  SUBMIT(state, obj) {
    state.submit = obj;
  },
};

const actions = {
  lockSubmit({ commit }, params) {
    return new Promise((resolve) => {
      commit("SUBMIT", params);
      resolve();
    });
  },
  unlockSubmit({ commit }) {
    return new Promise((resolve) => {
      commit("SUBMIT", undefined);
      resolve();
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
